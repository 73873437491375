import AppBarMyStory from "./appBarMyStory";
import { Card,Nav,Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import './style.css';
import { useDispatch, useSelector } from "react-redux";
import { getMyStoriesDraft, getMyStoriesRelease } from "../../features/myStory/myStoriesSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import MyStoryRelease from "./release/myStoryRelease";
import MyStoryDraft from "./draft/myStoryDraft";
import { resetMyStories } from "../../features/myStory/myStoriesSlice";
import {isMobile} from 'react-device-detect';
import Sizedbox from "../../utils/components/sizedbox";
const MyStoryPage = () => {
  const [selectedTab,setSelectedTab] = useState('publish');
  const [page,setPage] = useState(1);
  const myStories = useSelector((state) => state.myStories);
  
  const dispatch = useDispatch();

  const loadStory = () => {
    setPage(1);
    dispatch(resetMyStories());
    if(selectedTab==='draft')
      dispatch(getMyStoriesDraft({pageNumber: 1}));
    else dispatch(getMyStoriesRelease({pageNumber: 1}));
  }
  useEffect(() => {
   loadStory();
  },[selectedTab]);

const loadmore = () => {
    setPage(page + 1);
    if(selectedTab==='draft')
      dispatch(getMyStoriesDraft({pageNumber: page + 1}));
    else dispatch(getMyStoriesRelease({pageNumber: page + 1}));
  }

 


  return (
    <div>
        <AppBarMyStory/>
        <Container>
        <Card className={`my-4`} style={{borderRadius:'8px',width:isMobile ? '100%' : '40%' }}>
          <Card.Body className="p-1">
          <Nav 
            
            variant="pills" 
            activeKey={selectedTab}
            onSelect={(selectedKey) => {
              setSelectedTab(selectedKey);
            }}
          >
            <Nav.Item  className="w-50 text-center" >
              <Nav.Link eventKey="publish" >Diterbitkan</Nav.Link>
            </Nav.Item>
            <Nav.Item className="w-50 text-center">
              <Nav.Link eventKey="draft">Draf</Nav.Link>
            </Nav.Item>
          </Nav>
          </Card.Body>
          </Card>
          <InfiniteScroll 
            dataLength={myStories.data.length}
            hasMore={myStories.isLoadMore} 
            next={loadmore}
            loader={ <p>Loading</p>}>
            {selectedTab=== 'publish' && <MyStoryRelease myStories={myStories} onError={loadStory}/>}
            {selectedTab=== 'draft' && <MyStoryDraft myStories={myStories} onError={loadStory}/>}
          </InfiniteScroll>
          {isMobile && <Sizedbox height="70px" />}
        </Container>
    </div>
  )
}

export default MyStoryPage