interface QuillStyles {
  [key: string]: {
    [key: string]: string;
  };
}

// Generate indent styles dynamically
const generateIndentStyles = (
  maxIndent: number = 8,
  baseIndent: number = 3
): QuillStyles => {
  const indentStyles: QuillStyles = {};

  for (let i = 1; i <= maxIndent; i++) {
    indentStyles[`ql-indent-${i}`] = {
      "padding-left": `${baseIndent * i}em`,
    };
  }

  return indentStyles;
};

// Base Quill class mappings
const BASE_QUILL_STYLES: QuillStyles = {
  "ql-align-center": {
    "text-align": "center",
  },
  "ql-align-right": {
    "text-align": "right",
  },
  "ql-align-justify": {
    "text-align": "justify",
  },
};

// Combine base styles with generated indent styles
const QUILL_CLASS_MAPPINGS: QuillStyles = {
  ...BASE_QUILL_STYLES,
  ...generateIndentStyles(),
};

const convertQuillToInlineStyles = (
  html: string,
  options = { maxIndent: 8, baseIndent: 3 }
): string => {
  // Create a temporary container
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Get fresh mappings with custom options if provided
  const styleMappings = {
    ...BASE_QUILL_STYLES,
    ...generateIndentStyles(options.maxIndent, options.baseIndent),
  };

  // Process all elements with ql-* classes
  const elements = tempDiv.querySelectorAll("*");
  elements.forEach((element) => {
    const classes = Array.from(element.classList);

    // Build inline styles from ql-* classes
    const styles: string[] = [];
    classes.forEach((className) => {
      if (className.startsWith("ql-")) {
        const styleMapping = styleMappings[className];
        if (styleMapping) {
          Object.entries(styleMapping).forEach(([property, value]) => {
            styles.push(`${property}: ${value}`);
          });
        }
        // Remove the ql-* class
        element.classList.remove(className);
      }
    });

    // Apply inline styles
    if (styles.length > 0) {
      const existingStyle = element.getAttribute("style") || "";
      element.setAttribute(
        "style",
        `${existingStyle}${existingStyle ? ";" : ""}${styles.join(";")}`
      );
    }
  });

  // Remove empty elements and unnecessary spaces
  const cleanHtml = tempDiv.innerHTML
    .replace(/<p>&nbsp;<\/p>/g, "")
    .replace(/&nbsp;/g, " ")
    .replace(/\s+/g, " ")
    .trim();

  return cleanHtml;
};

// Example usage function with options
const convertQuillContent = (
  quillHtml: string,
  options?: { maxIndent?: number; baseIndent?: number }
) => {
  try {
    return convertQuillToInlineStyles(quillHtml, options);
  } catch (error) {
    console.error("Error converting Quill HTML:", error);
    return quillHtml;
  }
};

export {
  convertQuillContent,
  convertQuillToInlineStyles,
  generateIndentStyles,
};
