import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

interface QuillEditorProps {
  value: string;
  onChange?: (content: string) => void;
  onReady?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  modules?: any;
  formats?: string[];
  maxHeight?: string;
}

const QuillEditor: React.FC<QuillEditorProps> = ({
  value,
  onChange,
  placeholder = "Tulis isi cerita disini...",
  readOnly = false,
  modules = {},
  formats = [],
  maxHeight = "400px",
  onReady,
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (!editorRef.current) return;

    // Default modules configuration
    const defaultModules = {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ indent: "-1" }, { indent: "+1" }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { align: ["", "center", "right", "justify"] },
        ],
      ],
      ...modules,
    };

    // Initialize Quill
    quillRef.current = new Quill(editorRef.current, {
      theme: "snow",
      modules: defaultModules,
      formats: formats.length > 0 ? formats : undefined,
      placeholder,
      readOnly,
    });

    // Set initial content
    quillRef.current.root.innerHTML = value;

    // Handle content changes
    quillRef.current.on("text-change", () => {
      if (onChange && quillRef.current) {
        const content = quillRef.current.root.innerHTML;
        onChange(content);
      }
    });

    if (editorRef.current) {
      onReady && onReady();
      const editorArea = editorRef.current.querySelector(".ql-editor");

      if (editorArea) {
        (editorArea as HTMLElement).style.minHeight = "300px";
        (editorArea as HTMLElement).style.overflowY = "auto";
        (editorArea as HTMLElement).style.fontSize = "16px";
        (editorArea as HTMLElement).style.fontFamily = "Poppins";
        (editorArea as HTMLElement).style.fontWeight = "500";
        // (editorArea as HTMLElement).style.color = "#666E7D";
      }
    }

    // Cleanup
    return () => {
      if (quillRef.current) {
        quillRef.current.off("text-change");
      }
    };
  }, []);

  const convertToHTML = (text: string): string => {
    let html = text;

    // WhatsApp style formatting
    // Bold: *text*
    html = html.replace(/\*(.*?)\*/g, "<strong>$1</strong>");

    // Italic: _text_
    html = html.replace(/_(.*?)_/g, "<em>$1</em>");

    // Strikethrough: ~text~
    html = html.replace(/~(.*?)~/g, "<s>$1</s>");

    // Markdown formatting
    // Headers
    html = html.replace(/^# (.*$)/gm, "<h1>$1</h1>");
    html = html.replace(/^## (.*$)/gm, "<h2>$1</h2>");
    html = html.replace(/^### (.*$)/gm, "<h3>$1</h3>");

    // Lists
    // Unordered lists
    html = html.replace(/^\- (.*$)/gm, "<ul><li>$1</li></ul>");
    html = html.replace(/^\* (.*$)/gm, "<ul><li>$1</li></ul>");

    // Ordered lists
    html = html.replace(/^\d+\. (.*$)/gm, "<ol><li>$1</li></ol>");

    // Links
    html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');

    // Code blocks
    html = html.replace(/```([\s\S]*?)```/g, "<pre><code>$1</code></pre>");

    // Inline code
    html = html.replace(/`([^`]+)`/g, "<code>$1</code>");

    // Blockquotes
    html = html.replace(/^> (.*$)/gm, "<blockquote>$1</blockquote>");

    // Clean up any double spaces
    html = html.replace(/\s\s+/g, " ");

    return html;
  };

  // Update content if value prop changes
  useEffect(() => {
    if (quillRef.current && value !== quillRef.current.root.innerHTML) {
      quillRef.current.root.innerHTML = value;
    }
  }, [value]);

  return (
    <div className="quill-editor-container">
      <div ref={editorRef} />
    </div>
  );
};

export default QuillEditor;
