import {
  Navbar,
  Container,
  Card,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../utils/components/customTextField";
import { useEffect, useState } from "react";
import KbmCoverModal from "./kbmCoverModal";
import { useFilePicker } from "use-file-picker";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUploadCover,
  uploadCover,
} from "../../../features/upload/uploadCoverSlice";
import CoverBook from "../../../utils/components/coverBook";
import CustomSelect from "../../../utils/components/customSelect";
import CustomTag from "../../../utils/components/customTag";
import { toast } from "react-toastify";
import { upsertStory } from "../../../features/myStory/actionMyStorySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { getCategory } from "../../../features/master/categorySlice";
import CustomButton from "../../../utils/components/customButton";
import IconButton from "../../../utils/components/iconButton";
import CircleProfile from "../../../utils/components/circleProfile";
import { isMobile } from "react-device-detect";
import Sizedbox from "../../../utils/components/sizedbox";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
const createSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Kolom judul cerita wajib diisi!")
    .max(150, "Kolom judul maksimal 150 karakter"),
  synopsis: Yup.string()
    .trim()
    .required("Kolom sinopsis wajib diisi!")
    .min(50, "Sinopsis minimal 50 karakter")
    .max(5000, "Sinopsis maksimal 5000 karakter"),
  category: Yup.string().required("Kolom pilih kategori wajib diisi"),
  tag: Yup.array(),
});

const CreateMyStory = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [coverId, setCoverId] = useState(null);
  const uploadImage = useSelector((state) => state.uploadCover);
  const actionMyStory = useSelector((state) => state.actionMyStory);
  const category = useSelector((state) => state.category);
  const [isSave, setSave] = useState(false);
  const [openFileSelector, { plainFiles, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: [".jpg", ".png", ".jpeg"],
    maxFileSize: 3,
    multiple: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetUploadCover());
    dispatch(getCategory());
  }, [dispatch]);

  useEffect(() => {
    errors.forEach((i) => {
      if (i.fileSizeToolarge) {
        toast.error("Gambar maksimal 3 mb", { theme: "colored" });
      }
    });
  }, [errors]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      if (
        plainFiles[0].type === "image/jpeg" ||
        plainFiles[0].type === "image/jpg" ||
        plainFiles[0].type === "image/png"
      ) {
        dispatch(uploadCover(plainFiles[0]));
      } else {
        toast.error("Gambar harus bertipe .jpg,.jpeg,.png", {
          theme: "colored",
        });
      }
    }
  }, [plainFiles, dispatch]);

  useEffect(() => {
    setCoverImage(uploadImage.data.url);
    setCoverId(uploadImage.data._id);
  }, [uploadImage]);

  const changeKbmCover = (imageId, imageUrl) => {
    setCoverImage(imageUrl);
    setCoverId(imageId);
    setModalShow(false);
  };

  return (
    <>
      <KbmCoverModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        changeCoverKbm={changeKbmCover}
      />
      <Formik
        initialValues={{
          title: "",
          synopsis: "",
          category: "",
          tag: [],
          isShowWordCount: true,
        }}
        validationSchema={createSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!coverId) {
            toast.error("Cover buku tidak boleh kosong!", {
              theme: "colored",
            });
            setSubmitting(false);
          } else {
            dispatch(
              upsertStory({
                title: values.title,
                category: values.category,
                tags: values.tag,
                synopsis: values.synopsis,
                coverImg: coverId,
                isShowWordCount: values.isShowWordCount ? "active" : "disable",
              })
            )
              .then(unwrapResult)
              .then((callback) => {
                let url = "/";
                if (!isSave) {
                  url = `/create-chapter/${callback.data.story._id}`;
                }
                navigate(url, { replace: true });
              });
          }
        }}
      >
        {(props) => (
          <Form>
            <Navbar
              bg="white"
              style={{ borderBottom: "2px solid #e6e8eb" }}
              sticky="top"
            >
              <Container>
                <ButtonGroup>
                  <IconButton
                    faIcon={faArrowLeft}
                    onClick={() => navigate("/", { replace: true })}
                  />
                  <Sizedbox width="10px" />
                  <Navbar.Text>
                    <p className="title-appbar">Cerita Baru</p>
                  </Navbar.Text>
                </ButtonGroup>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {!isMobile && (
                    <>
                      <CustomButton
                        isLoading={isSave && actionMyStory.isLoading}
                        size="sm"
                        onClick={() => {
                          setSave(true);
                          props.handleSubmit();
                        }}
                        variant="secondary"
                      >
                        Simpan
                      </CustomButton>
                      <div style={{ width: "10px" }}></div>
                      <CustomButton
                        isLoading={!isSave && actionMyStory.isLoading}
                        size="sm"
                        onClick={() => {
                          setSave(false);
                          props.handleSubmit();
                        }}
                      >
                        Selanjutnya
                      </CustomButton>
                    </>
                  )}
                  <CircleProfile />
                </div>
              </Container>
            </Navbar>

            {isMobile && (
              <Navbar fixed="bottom" bg="white">
                <Container fluid>
                  <CustomButton
                    className="w-100"
                    isLoading={isSave && actionMyStory.isLoading}
                    onClick={() => {
                      setSave(true);
                      props.handleSubmit();
                    }}
                    variant="secondary"
                  >
                    Simpan
                  </CustomButton>
                  <div style={{ width: "20px" }}></div>
                  <CustomButton
                    className="w-100"
                    isLoading={!isSave && actionMyStory.isLoading}
                    onClick={() => {
                      setSave(false);
                      props.handleSubmit();
                    }}
                  >
                    Selanjutnya
                  </CustomButton>
                </Container>
              </Navbar>
            )}

            <Container>
              <Card className={`my-4 ${isMobile ? "p-2" : "p-4"}`}>
                <Card.Body>
                  <Row>
                    <Col lg={3} className="mx-auto">
                      {coverImage == null && (
                        <div
                          className="mx-auto"
                          style={{
                            width: "202px",
                            height: "282px",
                            backgroundColor: "#E6E8EB",
                            borderRadius: "6px",
                          }}
                        />
                      )}
                      {coverImage != null && (
                        <CoverBook
                          className="mx-auto d-block"
                          src={coverImage}
                          width="202px"
                          height="282px"
                        />
                      )}
                      <DropdownButton
                        disabled={uploadImage.isLoading ? true : false}
                        title={
                          uploadImage.isLoading ? "Loading..." : "Edit Cover"
                        }
                        style={{ color: "red" }}
                        className="d-flex justify-content-center mt-4 mb-4"
                      >
                        <Dropdown.Item onClick={() => setModalShow(true)}>
                          Kbm Sampul
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => openFileSelector()}>
                          Galeri
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                    <Col lg={9}>
                      <CustomTextField
                        name="title"
                        label="Judul"
                        maxLength="150"
                        isCountCharacter
                        placeholder="Tulis judul ceritamu"
                      />
                      <CustomSelect
                        label="Kategori"
                        name="category"
                        placeholder="Pilih Kategori cerita"
                        isLoading={category.isLoading}
                        options={
                          category?.data?.map((val) => ({
                            label: val.name,
                            value: val._id,
                          })) ?? []
                        }
                      />
                      <CustomTextField
                        as="textarea"
                        rows={5}
                        name="synopsis"
                        isCountCharacter={true}
                        maxLength="5000"
                        label="Sinopsis"
                        placeholder="Tulis deskripsi ceritamu"
                      />
                      <CustomTag
                        label="Kata Kunci"
                        value={props.values.tag}
                        touched={props.touched.tag}
                        onRemove={(val) =>
                          props.setFieldValue(
                            "tag",
                            props.values.tag.filter((tag) => tag !== val)
                          )
                        }
                        placeholder="Tulis kata kunci dan klik ENTER"
                        onEnter={(val) =>
                          props.setFieldValue("tag", [...props.values.tag, val])
                        }
                        errors={props.errors.tag}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "18px",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="isShowWordCount"
                          name="isShowWordCount"
                          onChange={(val) => {
                            props.setFieldValue(
                              "isShowWordCount",
                              val.target.checked
                            );
                          }}
                          checked={props.values.isShowWordCount}
                        />
                        <label
                          style={{
                            paddingLeft: "12px",
                            cursor: "pointer",
                            color: "#929AA7",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                          htmlFor="isShowWordCount"
                        >
                          Tampilkan Jumlah Kata {props.values.isShowWordCount}
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </Form>
        )}
      </Formik>
      {isMobile && <Sizedbox height="70px" />}
    </>
  );
};

export default CreateMyStory;
